import React, { useEffect, useState } from 'react';
import { formatCoin, formatPrice, formatPriceToDecimel } from '../../utils';
import { customGet, customPost } from '../../services/axios/axiosBase';
import { getCustomerInfo } from '../../helpers/getCustomerInfo';

const FormOfPayment = ({ setPaymentType, setBoletoUrl }) => {
  const [bolletoErro, setBolletoErro] = useState('');
  const [whoPay, setWhoPay] = useState('');
  const [clientName, setClientName] = useState('');
  const [courseName, setCourseName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [companyInfo, setCompanyInfo] = useState('');
  const [userData, setUserData] = useState();
  const [addressData, setAddressData] = useState();
  const [courseId, setCourseId] = useState();
  const [coursePrice, setCoursePrice] = useState();

  useEffect(() => {
    const whoPay = JSON.parse(localStorage.getItem('who_pay'));
    const userData = JSON.parse(localStorage.getItem('user_data'));
    const addressData = JSON.parse(localStorage.getItem('address_data'));
    const courseId = JSON.parse(localStorage.getItem('omd_course_id'));
    const studentData = JSON.parse(localStorage.getItem('student_data'));
    const courseData = JSON.parse(localStorage.getItem('course_name'));
    const companyData = JSON.parse(localStorage.getItem('company_data'));
    const price = JSON.parse(localStorage.getItem('omd_course_price'));

    const renderedPrice = formatPriceToDecimel(price);
    setCoursePrice(renderedPrice);
    setWhoPay(whoPay);
    if (whoPay === 'company') {
      const clientName = studentData.name;
      setClientName(clientName);
      setUserData(studentData);
      setCompanyInfo(companyData);
    } else if (whoPay === 'me') {
      const clientName = userData.name;
      setUserData(userData);
      setClientName(clientName);
    }
    const courseName = courseData;
    setCourseName(courseName);
    setAddressData(addressData);
    setCourseId(courseId);
  }, []);

  const submitBoleto = async (type) => {
    const studentInfo = JSON.parse(localStorage.getItem('student_data'));
    const coupon =
      localStorage?.getItem('omd_coupon') !== 'undefined'
        ? JSON.parse(localStorage?.getItem('omd_coupon'))
        : '';
    setIsSubmitting(true);
    const customerInfo = getCustomerInfo(whoPay, {
      userData,
      addressData,
      companyInfo,
      studentInfo,
    });
    const responseBody = {
      ...(coupon ? { coupon_id: coupon } : {}),
      course_id: courseId,
      payment_type:
        type === 'BOLETO'
          ? 'BOLETO'
          : type === 'CREDIT_CARD'
          ? 'CREDIT_CARD'
          : '',
      customer_info: customerInfo,
      ...(type === 'BOLETO'
        ? {
            boleto_info: {
              billing_type: 'BOLETO',
              description: 'payment with boleto',
              value: coursePrice,
            },
          }
        : {}),
      ...(type === 'CREDIT_CARD'
        ? {
            credit_info: {
              billing_type: 'CREDIT_CARD',
              description: 'payment with credit',
              value: coursePrice,
            },
          }
        : {}),
    };
    try {
      const response = await customPost({
        url: `/checkout/asaas`,
        body: responseBody,
      });
      setBoletoUrl(response.bank_slip_url);
      window.open(response.bank_slip_url, '_blank');
      setPaymentType(3);
      setBolletoErro('');
    } catch (error) {
      const errorMessage = error?.description
        ? error?.description
        : 'Erro ao gerar o boleto';
      setBolletoErro(errorMessage);
    }
    setIsSubmitting(false);
  };
  return (
    <div className="container-payment">
      <div className="title-user-pay">
        <p title-user-pay>
          <span>
            Olá <b>{clientName}</b> você escolheu o curso <b>{courseName}</b>,{' '}
          </span>
          <span>selecione a forma de pagamento</span>
        </p>
      </div>
      <div className=" buttons-payments">
        <div className="button-payment-card">
          <Coupon />
        </div>
        <div className="button-payment-card">
          <ButtonPix onClick={() => setPaymentType(2)} />
          <div className="separator" />
          <ButtonBankTransfer onClick={() => setPaymentType(1)} />
        </div>
        <div className="button-payment-card">
          <ButtonTicket
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={() => submitBoleto('BOLETO')}
          />
          <div className="separator" />
          <ButtonCreditCard onClick={() => setPaymentType(4)} />
        </div>
        {bolletoErro && <p className="error-feedback">{bolletoErro}</p>}
      </div>
    </div>
  );
};

export default FormOfPayment;

const Coupon = () => {
  const [coupon, setCoupon] = useState();
  const [couponSuccess, setCouponSuccess] = useState(false);
  const [showCouponData, setShowCouponData] = useState(false);
  const [currentCoupon, setCurrentCoupon] = useState('');
  const [couponPorcentage, setCouponPorcentage] = useState('');
  const [price, setPrice] = useState('');
  const [priceWithDescount, setPriceWithDescount] = useState('');
  const getCoupon = async () => {
    try {
      const response = await customGet(`/coupon/code/${currentCoupon}`);
      if (response.id) {
        setCouponSuccess(true);
        setShowCouponData(true);
        const oldPrice = JSON.parse(localStorage.getItem('omd_course_price'));
        setPrice(formatPrice(oldPrice));
        setCouponPorcentage(response.discount);
        const priceWithCoupon =
          formatPriceToDecimel(oldPrice) * ((100 - response.discount) / 100);
        setPriceWithDescount(formatPrice(priceWithCoupon));
        setTimeout(() => {
          if (document.getElementById('feedback-coupon')) {
            document.getElementById('feedback-coupon').style.animation =
              'couponDissappear 1s ease';
          }
          document.getElementById('price').innerHTML =
            'R$ ' +
            formatPrice(priceWithCoupon) +
            ' (preço com cupom de desconto)';
          localStorage.setItem(
            'omd_course_price_with_coupon',
            JSON.parse(priceWithCoupon)
          );
        }, 3000);
        setTimeout(() => {
          setCouponSuccess(false);
        }, 4000);
      } else {
        setCoupon('Cupom Invalido');
        setCouponSuccess(false);
        setShowCouponData(false);
      }
    } catch (e) {
      console.log(e);
      setCoupon('Cupom Invalido');
      setCouponSuccess(false);
      setShowCouponData(false);
    }
  };

  useEffect(() => {
    localStorage.setItem('omd_coupon', JSON.stringify(coupon));
    setCurrentCoupon(coupon);
  }, [coupon]);

  return (
    <div className="center-div">
      <div className="coupon-card">
        {couponSuccess && (
          <div className={'feedback-coupon'} id="feedback-coupon">
            Parabéns! seu cupom foi aceito.
          </div>
        )}
        <div className="coupon-text">Informe seu cupom </div>
        <div>
          <input
            type="text"
            className="coupon-code"
            value={coupon}
            onChange={(e) => setCoupon(e.target.value)}
          ></input>
        </div>
        <button className="btn-coupon" onClick={getCoupon}>
          CONFIRMAR
        </button>
      </div>
      {showCouponData && (
        <div class="grid-container">
          <div class="grid-item">porcentagem do desconto</div>
          <div class="grid-item">{`${couponPorcentage}%`}</div>
          <div class="grid-item">valor sem desconto</div>
          <div class="grid-item">{`R$${price}`}</div>
          <div class="grid-item">valor com desconto</div>
          <div class="grid-item">{`R$${priceWithDescount}`}</div>
        </div>
      )}
    </div>
  );
};

const ButtonPix = ({ onClick }) => {
  return (
    <div className="button-payment">
      <button type="button" className="button-working" onClick={onClick}>
        <img className="img-card-form-pay" src="img/pix.png"></img>
        Pix
        <div>
          <span>Liberação imediata</span>
        </div>
      </button>
    </div>
  );
};

const ButtonBankTransfer = ({ onClick }) => {
  return (
    <div className="button-payment">
      <button type="button" className="button-working" onClick={onClick}>
        <img className="img-card-form-pay" src="img/transferencia.png"></img>
        Transferência Bancária
      </button>
    </div>
  );
};

const ButtonTicket = ({ onClick, disabled, loading }) => {
  return (
    <div className="button-payment">
      <button
        disabled={disabled}
        type="button"
        className="button-working"
        onClick={onClick}
      >
        <img className="img-card-form-pay" src="img/boleto.png"></img>
        {!loading ? 'Boleto' : '... Aguarde'}
      </button>
    </div>
  );
};

const ButtonCreditCard = ({ onClick }) => {
  return (
    <div className="button-payment">
      <button type="button" className="button-working" onClick={onClick}>
        <img className="img-card-form-pay" src="img/cartao.png"></img>
        Cartão de Crédito
      </button>
    </div>
  );
};
