import React, { useEffect, useState } from 'react';
import { BsSearch } from "react-icons/bs";
import { Address, useCep } from '../company-data';
import { cpfCheck, justNumbers, rejectInicialSpace, rejectNumbers, dddMask } from '../../utils';

export const UserData = () => {

    const initialValues = { name: "", cpf: "", email: "", email2: "", phone: "", phone2: "", terms: false }
    const [ formValues, setFormValues ] = useState(initialValues)
    const [ formErrors, setFormErrors ] = useState({})


    useEffect(() => {
        JSON.parse(localStorage.getItem('user_data')) && setFormValues(JSON.parse(localStorage.getItem('user_data')))
    }, [])

    const handleChange = element => {
        const { name, value } = element

        if(name === 'terms') {
            const { checked } = element
            setFormValues({ ...formValues, [name]: checked })
        } else {

            let correctValue = rejectInicialSpace(value)
            
            if(name === 'cpf' || name === 'phone' || name === 'phone2') {
                correctValue = justNumbers(correctValue)
            }
    
            if(name === 'phone' || name === 'phone2') {
                correctValue = dddMask(correctValue)
            }
    
            if(name === 'name') {
                correctValue = rejectNumbers(correctValue)
            }
    
            setFormValues({ ...formValues, [name]: correctValue })
        }

    }

    useEffect(() => {
        localStorage.setItem('dataErros', JSON.stringify(formErrors))
    }, [formErrors])


    useEffect(() => {
        localStorage.setItem('user_data', JSON.stringify(formValues))
        setFormErrors(validate(formValues))
    }, [formValues])


    const validate = values => {
        const errors = {}
        const regexp = new RegExp(/^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i)
        if(!values.name){
            errors.name = "Nome é obrigatório"
        }
        if(!values.cpf){
            errors.cpf = "CPF é obrigatório"
        } else if(cpfCheck(values.cpf)) {
            errors.cpf = cpfCheck(values.cpf)
        } 
        if(!values.email){
            errors.email = "E-mail é obrigatório"
        } else if(!regexp.test(values.email)) {
            errors.email = "E-mail inválido"
        }
        if(!values.phone){
            errors.phone = "Telefone é obrigatório"
        }
        if(!values.terms) {
            errors.terms = "Aceite os termos para prosseguir"
        }
        return errors
    }

    return <div className='container-input'>
        <TextInput label={"Nome completo"} required={true} name={'name'} value={formValues.name} onChange={handleChange} error={formErrors.name}/>
        <TextInput max={11} label="CPF" required={true} name={'cpf'}  value={formValues.cpf} onChange={handleChange} error={formErrors.cpf}/>
        <TextInput label="Email" required={true} name={'email'}  value={formValues.email} onChange={handleChange} error={formErrors.email}/>
        <TextInput label="Email opcional" name={'email2'}  value={formValues.email2} onChange={handleChange}/>
        <div className='more-component-input'>
            <TextInput max={15} label="Telefone principal para contato" name={'phone'}  value={formValues.phone} onChange={handleChange} error={formErrors.phone}/>
            <TextInput max={15} label="Telefone opcional para contato" name={'phone2'}  value={formValues.phone2} onChange={handleChange} />
        </div>
        <PolicyAndPrivacy onChange={handleChange} name="terms" value={formValues.terms} error={formErrors.terms}/>
    </div>;
}
export const AddressUser = ({ setStep }) => {
    const { fetchCep, setCep, cep, cepData } = useCep();

    return <div className='container-input'>
        {/* <div className='width-input'>
            <TextInput label="CEP" required={true} icon={<div onClick={fetchCep}><IconSearch /></div>} onChange={value => setCep(value)} />
        </div> */}
        <Address cepData={cepData} />
        <PolicyAndPrivacy />
        {/* <ButtonSaveAndContinue onClick={() => setStep(1)} /> */}
    </div>;
}

export const IconSearch = () => {
    return <span className='search-text'><BsSearch /></span>
}

export default UserData;

export const TextInput = ({max, label, icon, required, onChange, value, name, error }) => {
    return <div className='data-input'>
        <label className='title-iput' for={name}>{label}{required && <span className='asterisk'>*</span>}</label>
        <div className='input-style'>
            <input maxLength={max && max} value={value} onChange={e => onChange && onChange(e.target)} name={name} className='form-input' type="text" id={name} />
            {icon}
        </div>
        <div className='invalid-feedback'>{ error }</div>
    </div>;
}

export const PolicyAndPrivacy = ({ onChange, name, value, error }) => {
    return <div className='check-privacy'>
        <input type="checkbox" id={name} name={name} value={value} onChange={e => onChange && onChange(e.target)} checked={value}></input>
        <label for="vehicle1" className='text-color-grey '> Li e aceito os <a href="https://ead2.omd.com.br/terms-of-use"><b>Termos de Serviço e Política de Privacidade</b></a></label>
        <br></br>
        <div className='invalid-feedback'>{ error }</div>
    </div>;
}

export const ButtonSaveAndContinue = ({ onClick }) => {
    return <div className='button'>
        <button type="button" className='button-save-continue' onClick={onClick}>Salvar e Continuar</button>
    </div>;
}

export const ButtonReturn = ({ onClick }) => {
    return <div className='button'>
        <button type="button" className='button-return' onClick={onClick}>Voltar</button>
    </div>;
}

