import React, { useState } from 'react';
import DataForTransfer from '../../screen/data-for-transfer';
import FormOfPayment from '../../screen/form-of-payment';
import HomeScreen from '../../screen/home';
import Success from '../../screen/success';

const HomeContainer = () => {
  const [step, setStep] = useState(0);
  return (
    <>
      {step === 0 && <HomeScreen setStep={setStep} />}
      {/* <UserData />
    <CompanyData /> */}
      {/* {step === 1 && <StudentData setStep={setStep} />} */}
      {step === 1 && <FormOfPayment setStep={setStep} />}
      {step === 2 && <DataForTransfer setStep={setStep} />}
      {step === 3 && <Success />}
    </>
  );
};

export default HomeContainer;
