import React, { useState, useEffect } from 'react';
import { TextInput } from '../user-data';
import {
  justNumbers,
  rejectInicialSpace,
  dddMask,
  validateCNPJ,
} from '../../utils';

export const useCep = () => {
  const [cep, setCep] = useState();
  const [cepData, setCepData] = useState({});

  const fetchCep = async () => {
    const data = await fetch(`https://viacep.com.br/ws/${cep}/json/`).then(
      (response) => response.json()
    );
    setCepData(data);
  };

  return { fetchCep, setCep, cep, cepData };
};

const CompanyData = ({ setStep }) => {
  const initialValues = {
    corporate_name: '',
    company_branch: '',
    invoice_email: '',
    responsible_payment_email: '',
    cnpj: '',
    phone: '',
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    JSON.parse(localStorage.getItem('company_data')) &&
      setFormValues(JSON.parse(localStorage.getItem('company_data')));
  }, []);

  useEffect(() => {
    setFormErrors(validate(formValues));
    localStorage.setItem('company_data', JSON.stringify({ ...formValues }));
  }, [formValues]);

  useEffect(() => {
    localStorage.setItem('dataErros', JSON.stringify(formErrors));
  }, [formErrors]);

  const handleChange = (element) => {
    const { name, value } = element;
    let correctValue = rejectInicialSpace(value);

    if (name === 'phone' || name === 'phone2') {
      correctValue = justNumbers(correctValue);
      correctValue = dddMask(correctValue);
    }

    if (name === 'cnpj') {
      var x = correctValue
        .replace(/\D/g, '')
        .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
      correctValue = !x[2]
        ? x[1]
        : x[1] +
          '.' +
          x[2] +
          '.' +
          x[3] +
          '/' +
          x[4] +
          (x[5] ? '-' + x[5] : '');
    }

    setFormValues({ ...formValues, [name]: correctValue });
  };

  const validate = (values) => {
    const errors = {};
    const regexp = new RegExp(
      /^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i
    );
    if (!values.corporate_name) {
      errors.corporate_name = 'Nome da compania é obrigatório';
    }

    if (!values.invoice_email) {
      errors.invoice_email = 'E-mail para envio de notas fiscais é obrigatório';
    } else if (!regexp.test(values.invoice_email)) {
      errors.invoice_email = ' E-mail inválido';
    }
    if (!values.responsible_payment_email) {
      errors.responsible_payment_email =
        'E-mail para responsável pelo pagamento é obrigatório';
    } else if (!regexp.test(values.responsible_payment_email)) {
      errors.responsible_payment_email = 'E-mail inválido';
    }
    if (!values.cnpj) {
      errors.cnpj = 'CNPJ é obrigatório';
    } else if (!validateCNPJ(values.cnpj)) {
      errors.cnpj = 'CNPJ inválido';
    }
    if (!values.phone) {
      errors.phone = 'Telefone do responsável pelo pagamento é obrigatório';
    }
    return errors;
  };

  return (
    <div className="container-input">
      <hr className="hr-data" />
      <div className="more-component-input">
        <TextInput
          onChange={handleChange}
          error={formErrors.corporate_name}
          label="Razão Social"
          required={true}
          name="corporate_name"
          value={formValues.corporate_name}
        />
        <TextInput
          onChange={handleChange}
          error={formErrors.cnpj}
          label="CNPJ"
          required={true}
          name="cnpj"
          value={formValues.cnpj}
        />
      </div>
      <TextInput
        onChange={handleChange}
        error={formErrors.invoice_email}
        label="E-mail para envio da nota fiscal"
        required={true}
        name="invoice_email"
        value={formValues.invoice_email}
      />
      <TextInput
        onChange={handleChange}
        error={formErrors.responsible_payment_email}
        label="E-mail do responsável pelo pagamento"
        required={true}
        name="responsible_payment_email"
        value={formValues.responsible_payment_email}
      />
      <div className="more-component-input">
        <TextInput
          max={15}
          onChange={handleChange}
          error={formErrors.phone}
          label="Telefone do responsável pelo pagamento"
          required={true}
          name="phone"
          value={formValues.phone}
        />
        <TextInput
          onChange={handleChange}
          label="Ramal"
          required={false}
          name="company_branch"
          value={formValues.company_branch}
        />
      </div>
    </div>
  );
};

export const Address = ({ pay }) => {
  const initialValues = {
    cep: '',
    number: '',
    uf: '',
    city: '',
    address: '',
    complement: '',
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    JSON.parse(localStorage.getItem('address_data')) &&
      setFormValues(JSON.parse(localStorage.getItem('address_data')));
  }, []);

  const handleChange = (element) => {
    const { name, value } = element;
    if (name === 'cep') {
      const cep = value.replace('-', '');
      if (cep.length === 8) {
        fetch(`https://viacep.com.br/ws/${value}/json/`)
          .then((response) => response.json())
          .then((response) => {
            setFormValues({
              ...formValues,
              cep: response.cep,
              uf: response.uf,
              address: response.logradouro,
              city: response.localidade,
            });
          });
      } else {
        setFormValues({ ...formValues, [name]: value });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  useEffect(() => {
    localStorage.setItem('dataErros', JSON.stringify(formErrors));
  }, [formErrors]);

  useEffect(() => {
    setFormErrors(validate(formValues));
    localStorage.setItem('address_data', JSON.stringify({ ...formValues }));
  }, [formValues]);

  const validate = (values) => {
    const errors = {};

    if (!values.cep) {
      errors.cep = 'Cep é obrigatório';
    } else if (values.cep > 8) {
      errors.cep = 'Cep inválido';
    }
    if (!values.address) {
      errors.address = 'Endereço é obrigatório';
    }
    if (!values.city) {
      errors.city = 'Cidade é obrigatório';
    }
    if (!values.number) {
      errors.number = 'Numero é obrigatório';
    }
    if (!values.uf) {
      errors.uf = 'Unidade federativa é obrigatório';
    }
    return errors;
  };

  return (
    <>
      <div className="step-title">
        {pay === '1' ? 'Endereço da Empresa' : 'Seu Endereço'}
      </div>
      <div className="width-input">
        <TextInput
          onChange={handleChange}
          error={formErrors.cep}
          name="cep"
          value={formValues.cep}
          label="CEP"
          required={true}
        />
      </div>
      <div className="more-component-input">
        <TextInput
          onChange={handleChange}
          error={formErrors.address}
          name="address"
          value={formValues.address}
          label="Endereço"
          required={true}
        />
        <TextInput
          onChange={handleChange}
          error={formErrors.number}
          name="number"
          value={formValues.number}
          label="Número"
          required={true}
        />
      </div>
      <div className="more-component-input">
        <TextInput
          onChange={handleChange}
          error={formErrors.uf}
          name="uf"
          value={formValues.uf}
          label="Estado"
          required={true}
        />
        <TextInput
          onChange={handleChange}
          error={formErrors.city}
          name="city"
          value={formValues.city}
          label="Cidade"
          required={true}
        />
        <TextInput
          onChange={handleChange}
          error={formErrors.complement}
          vallue={formValues.complement}
          name="complement"
          label="Complemento"
        />
      </div>
    </>
  );
};

export default CompanyData;
