import React, { useEffect, useState } from 'react';
import { cpfCheck, rejectInicialSpace, justNumbers, rejectNumbers } from '../../utils';
import { PolicyAndPrivacy, TextInput } from '../user-data';


const StudentData = ({setStep}) => {
    
    const initialValues = { name: "", cpf: "", email: "", email2: "", terms: false}
    const [ formValues, setFormValues ] = useState(initialValues)
    const [ formErrors, setFormErrors ] = useState({})


    useEffect(() => {
        JSON.parse(localStorage.getItem('student_data')) && setFormValues(JSON.parse(localStorage.getItem('student_data')))
    }, [])

    const handleChange = element => {
        const { name, value } = element
        
        if(name === 'terms') {
            const { checked } = element
            setFormValues({ ...formValues, [name]: checked })
        } else {
            let correctValue = rejectInicialSpace(value)
        
            if(name === 'cpf') {
                correctValue = justNumbers(correctValue)
            }

            if(name === 'name') {
                correctValue = rejectNumbers(correctValue)
            }

            setFormValues({ ...formValues, [name]: correctValue })
        }
    }

    useEffect(() => {
        localStorage.setItem('dataErros', JSON.stringify(formErrors))
    }, [formErrors])


    useEffect(() => {
        localStorage.setItem('student_data', JSON.stringify(formValues))
        setFormErrors(validate(formValues))
    }, [formValues])


    const validate = values => {
        const errors = {}
        const regexp = new RegExp(/^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i)
        if(!values.name){
            errors.name = "Nome é obrigatório"
        }
        if(!values.cpf){
            errors.cpf = "CPF é obrigatório"
        } else if (cpfCheck(values.cpf)){
            errors.cpf = cpfCheck(values.cpf)
        }
        if(!values.email){
            errors.email = "E-mail é obrigatório"
        } else if(!regexp.test(values.email)) {
            errors.email = "E-mail inválido"
        }
        if(!values.terms) {
            errors.terms = "Aceite os termos para prosseguir"
        }

        return errors
    }

    return <>
        <hr className='hr-data' />
        <div className='step-title'>Dados do Aluno</div>
        <div className='more-component-input'>
            <TextInput label="Nome" required={true} onChange={handleChange} name="name" value={formValues.name} error={formErrors.name}/>
            <TextInput max={11} label="CPF" required={true} onChange={handleChange} name="cpf" value={formValues.cpf} error={formErrors.cpf}/>
        </div>
        <TextInput label="Email" required={true} onChange={handleChange} name="email" value={formValues.email} error={formErrors.email}/>
        <TextInput label="Email opcional" onChange={handleChange} name="email2" value={formValues.email2} error={formErrors.email2}/>
        <PolicyAndPrivacy onChange={handleChange} name="terms" value={formValues.terms} error={formErrors.terms}/>
        {/* <ButtonSaveAndContinue onClick={() => setStep(2)} />
        <ButtonReturn/> */}
    </>;
}

export default StudentData;