import React, { useEffect, useState } from 'react';
import { formatCoin, formatPriceToDecimel } from '../../utils';
import { customGet, customPost } from '../../services/axios/axiosBase';
import { getCustomerInfo } from '../../helpers/getCustomerInfo';

const DataForTransfer = ({ setStep, paymentType, boletoUrl }) => {
  let price = JSON.parse(localStorage.getItem('omd_course_price'));

  if (JSON.parse(localStorage.getItem('omd_course_price_with_coupon'))) {
    price = JSON.parse(localStorage.getItem('omd_course_price_with_coupon'));
  }

  let clientName = '';

  let typeOfPayment = '';

  let dataPost = '';

  let id = '';

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('checkout_id')) !== undefined) {
      id = JSON.parse(localStorage.getItem('checkout_id'));
    }
  }, []);

  const whoPay = JSON.parse(localStorage.getItem('who_pay'));

  if (whoPay === 'company') {
    clientName = JSON.parse(localStorage.getItem('student_data')).name;
  } else if (whoPay === 'me') {
    clientName = JSON.parse(localStorage.getItem('user_data')).name;
  }

  let paymentOption = {};

  if (paymentType === 1) {
    paymentOption = {
      type: 'Transferência Bancária',
      subTitle: 'a Transferência Bancária',
    };
    typeOfPayment = 'TRANSFER';
  }
  if (paymentType === 2) {
    paymentOption = { type: 'Pix', subTitle: 'o Pix' };
    typeOfPayment = 'PIX';
  }
  if (paymentType === 3) {
    paymentOption = { type: 'Boleto', subTitle: '' };
    typeOfPayment = 'TIKET';
  }
  if (paymentType === 4) {
    paymentOption = {
      type: 'Cartão de crédito',
      subTitle: 'o Cartão de crédito',
    };
    typeOfPayment = 'CREDIT';
  }

  //POST 2

  const sendPost = () => {
    const whoPayCourse = JSON.parse(localStorage.getItem('who_pay'));
    const addressData = JSON.parse(localStorage.getItem('address_data'));
    const course = JSON.parse(localStorage.getItem('omd_course_id'));
    const courseName = JSON.parse(localStorage.getItem('course_name'));

    const coursePrice = JSON.parse(
      localStorage.getItem('omd_course_price_with_coupon')
    )
      ? JSON.parse(localStorage.getItem('omd_course_price_with_coupon'))
      : JSON.parse(localStorage.getItem('omd_course_price'));

    let coupon = 'NULL';

    try {
      if (JSON.parse(localStorage.getItem('omd_coupon'))) {
        coupon = JSON.parse(localStorage.getItem('omd_coupon'));
      }
    } catch {
      coupon = 'NULL';
    }

    if (whoPayCourse === 'company') {
      const studendData = JSON.parse(localStorage.getItem('student_data'));
      const companyData = JSON.parse(localStorage.getItem('company_data'));
      dataPost = {
        id: id && id,
        payment_type: typeOfPayment,
        price: coursePrice,
        course_name: courseName,
        course: course,
        coupon: coupon,
        student_data: { ...studendData },
        company_data: { ...companyData },
        address: { ...addressData },
      };
    } else if (whoPayCourse === 'me') {
      const userData = JSON.parse(localStorage.getItem('user_data'));
      dataPost = {
        id: id && id,
        payment_type: typeOfPayment,
        price: coursePrice,
        course_name: courseName,
        course: course,
        coupon: coupon,
        user_data: { ...userData },
        address: { ...addressData },
      };
    }

    fetch('https://hmlcheckout.omd.com.br' + process.env.REACT_APP_CHECKOUT, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: process.env.REACT_APP_TOKEN_CHECKOUT,
      },
      method: 'POST',
      body: JSON.stringify(dataPost),
    })
      .then((res) => res.json())
      .then((res) => {
        localStorage.setItem('checkout_id', JSON.stringify(res.id));
        return res;
      })
      .then((res) => console.log(res))
      .catch(function (err) {
        console.log(err);
      });
  };

  useEffect(() => {
    sendPost();
  }, []);

  return (
    <div className="container-payment">
      <div className="title-user-pay-transfere">
        <span className="payment-name-greet">Olá, {clientName}</span>
        <span>
          você selecionou a forma de pagamento <b>{paymentOption.type}</b>
        </span>
      </div>
      <hr />
      <div className="">
        {/*  <span className='title-trasnfer-card'>{paymentOption.type}</span> */}
        {paymentType === 1 && (
          <span className="subtitle-trasnfer-card">
            Dados para efetuar {paymentOption.subTitle}
          </span>
        )}
        {paymentType === 1 && <DataBankTransfer price={price} />}
        {paymentType === 2 && <DataPix price={price} />}
        {paymentType === 3 && <DataTiket boletoUrl={boletoUrl} />}
        {paymentType === 4 && <DataCredit />}
        {/* <ButtonFinish onClick={() => setStep(3)}/> */}
      </div>
    </div>
  );
};

export default DataForTransfer;

const DataPix = ({ price }) => {
  const [base64Image, setBase64Image] = useState('');
  const [error, setError] = useState('');
  const [responseValue, setValue] = useState('');

  useEffect(() => {
    const whoPay = JSON.parse(localStorage.getItem('who_pay'));
    const userData = JSON.parse(localStorage.getItem('user_data'));
    const addressData = JSON.parse(localStorage.getItem('address_data'));
    const companyInfo = JSON.parse(localStorage.getItem('company_data'));
    const studentInfo = JSON.parse(localStorage.getItem('student_data'));
    const courseId = JSON.parse(localStorage.getItem('omd_course_id'));
    const coupon =
      localStorage?.getItem('omd_coupon') !== 'undefined'
        ? JSON.parse(localStorage?.getItem('omd_coupon'))
        : '';
    const price = JSON.parse(localStorage.getItem('omd_course_price'));
    const renderedPrice = formatPriceToDecimel(price);
    const customerInfo = getCustomerInfo(whoPay, {
      userData,
      addressData,
      companyInfo,
      studentInfo,
    });

    const getPixQr = {
      course_id: courseId,
      ...(coupon ? { coupon_id: coupon } : {}),
      payment_type: 'PIX',
      customer_info: customerInfo,
      boleto_info: {
        billing_type: 'PIX',
        description: 'payment with PIX',
        value: renderedPrice,
      },
    };
    customPost({ url: '/checkout/asaas/pix', body: getPixQr })
      .then((result) => {
        setBase64Image(result.encoded_image);
        setValue(result.value);
        setError('');
      })
      .catch((e) => {
        setBase64Image('');
        setError('erro carregando a imagem, tente novamente mais tarde');
      });
  }, []);
  return (
    <div className="data-bank-transfer">
      <div className="qr-code-container">
        <div className="title-qr-code">Pague com o QR code</div>
        {base64Image ? (
          <img
            className="qr-code"
            src={`data:image/png;base64, ${base64Image}`}
          ></img>
        ) : error ? (
          <span className="error-feedback">{error}</span>
        ) : (
          <span>Carregando Imagem...</span>
        )}
      </div>
      <span>Ou utilize os dados abaixo para efetuar o pix:</span>
      <br></br>
      <span>
        <b>Instituição:</b> ASAAS IP.S.A
      </span>
      <span>
        <b>Nome:</b> OMD Soluções para Integridade Corporativa Ltda.
      </span>
      <span>
        <b>CNPJ:</b> CNPJ: 06.181.338/0001-73
      </span>
      <span>
        <b>Valor:</b> {responseValue ? 'R$ ' + responseValue : '-'}
      </span>
    </div>
  );
};

const DataTiket = ({ boletoUrl }) => {
  let email = '';

  let whoPay = JSON.parse(localStorage.getItem('who_pay'));
  let defaultEmail = '';

  if (whoPay === 'me') {
    email = JSON.parse(localStorage.getItem('user_data')).email;
  } else if (whoPay === 'company') {
    email = JSON.parse(
      localStorage.getItem('company_data')
    ).responsible_payment_email;
    if (whoPay === 'me') {
      email = JSON.parse(localStorage.getItem('user_data')).email;
    } else if (whoPay === 'company') {
      email = JSON.parse(
        localStorage.getItem('company_data')
      ).responsible_payment_email;
    } else {
      defaultEmail = 'cadastrado';
      defaultEmail = 'cadastrado';
    }
  }
  const downloadFile = () => {
    window.location.href = boletoUrl;
  };
  return (
    <div className="data-bank-boleto">
      <span>
        {/* Seu pedido foi aceito e o boleto será gerado pela equipe financeira da
        OMD e enviado para o e-mail <b>{email && email}</b> {defaultEmail} para
        recebimento dos documentos financeiros */}
        Seu pedido foi aceito e o boleto será gerado pela equipe financeira da
        OMD e deverá abrir em uma nova aba do seu navegador.
      </span>
      <div className="mt-4">
        {boletoUrl ? (
          <div>
            <p className="text-sm">
              Caso o pop-up do boleto não tenha sido aberto, clique no botão
              abaixo para ser redirecionado ou habilite a ativação do boleto.
            </p>

            <button
              className="boleto-button"
              type="button"
              onClick={downloadFile}
            >
              Ir para o boleto
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const DataCredit = () => {
  return (
    <div className="data-bank-transfer">
      <div>Algo sobre o pagamento com cartão de crédito</div>
    </div>
  );
};

const DataBankTransfer = ({ price }) => {
  return (
    <div className="data-bank-transfer-container">
      <div className="data-bank-transfer-element">
        <span className="payment-info">Razão Social</span>
        <span>OMD Soluções para Integridade Corporativa Ltda.</span>
      </div>
      <div className="data-bank-transfer-element">
        <span className="payment-info">CNPJ</span>
        <span>06.181.338/0001-73</span>
      </div>

      <div className="data-bank-transfer-element">
        <span className="payment-info">Banco</span>
        <span>001 BANCO DO BRASIL</span>
      </div>
      <div className="data-bank-transfer-element-row">
        <div className="data-bank-transfer-element">
          <span className="payment-info">Agência</span>
          <span>3174-7</span>
        </div>

        <div className="data-bank-transfer-element">
          <span className="payment-info">Conta</span>
          <span>11.134-1</span>
        </div>

        <div className="data-bank-transfer-element">
          <span className="payment-info">Tipo de Conta</span>
          <span>C/C</span>
        </div>
      </div>
      <div className="data-bank-transfer-element">
        <span className="payment-info">Valor</span>
        <span>{'R$ ' + formatCoin(price)}</span>
      </div>
    </div>
  );
};

export const ButtonFinish = ({ onBack, onClick, disabled, loading }) => {
  return (
    <div className="button-finish-container">
      <button type="button" onClick={onBack}>
        VOLTAR
      </button>
      <button disabled={disabled} type="button" onClick={onClick}>
        {!loading ? 'FINALIZAR' : '... Aguarde'}
      </button>
    </div>
  );
};
