import './App.css';
import HomeContainer from './container/Home';
import { NameValue } from './screen/home';
import { removeItems } from './utils';

function App() {
  removeItems();
  const id = window.location.search.replace('?id=', '');
  localStorage.setItem('omd_course_id', JSON.stringify(id));
  return (
    <div className="App">
      <NameValue idCourse={id} />
      <HomeContainer />
    </div>
  );
}

export default App;
