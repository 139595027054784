import axios from 'axios';

export const API = 'https://hmlcheckout.omd.com.br/';
// export const API = 'http://localhost:8080';

export const defaultParams = () => {
  return {
    baseURL: API,
    headers: {
      'Content-Type': 'application/json',
    },
  };
};

export const api = axios.create({
  ...defaultParams(),
});
