import { api, defaultParams } from './axios';

export default async (url) => {
  const response = await (
    await api.get(url, {
      ...defaultParams(),
    })
  ).data;
  return response;
};
