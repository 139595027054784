import React, { useEffect, useState } from 'react';
import CompanyData, { Address } from '../company-data';
import UserData from '../user-data';
import { BsArrowRight } from 'react-icons/bs';
import FormOfPayment from '../form-of-payment';
import DataForTransfer, { ButtonFinish } from '../data-for-transfer';
import Success from '../success';
import StudentData from '../student-data';
import { formatCoin, formatPriceToDecimel, removeItems } from '../../utils';
import CreditCard from '../creditCard';
import { customPost } from '../../services/axios/axiosBase';
import { getCustomerInfo } from '../../helpers/getCustomerInfo';
import AsaasSvg from '../../svg/AsaasSvg';
import ProtectedSvg from '../../svg/ProtectedSvg';

const HomeScreen = ({}) => {
  const [step, setStep] = useState(0);
  const [pay, setPay] = useState();
  const [paymentType, setPaymentType] = useState(1);
  useEffect(() => {
    if (pay === '0') {
      localStorage.setItem('who_pay', JSON.stringify('me'));
      localStorage.removeItem('company_data');
      localStorage.removeItem('address_data');
      localStorage.removeItem('student_data');
    } else if (pay === '1') {
      localStorage.setItem('who_pay', JSON.stringify('company'));
      localStorage.removeItem('user_data');
      localStorage.removeItem('address_data');
    }
  }, [pay]);
  return (
    <div>
      {/* <WhoPay onChange={setPay} /> */}
      {/* {pay === "0" && <UserData setStep={setStep} />}
        {pay === "1" && <CompanyData setStep={setStep} />} */}
      <StepperCompany step={step} setStep={setStep} pay={pay} />
      <DataCard
        pay={pay}
        setPay={setPay}
        step={step}
        setStep={setStep}
        paymentType={paymentType}
        setPaymentType={setPaymentType}
      />
      <OmdFooter />
    </div>
  );
};

export default HomeScreen;

export const NameValue = ({ idCourse }) => {
  const [logo, setLogo] = useState();
  const [title, setTitle] = useState();
  const [price, setPrice] = useState();

  useEffect(() => {
    fetch(
      process.env.REACT_APP_BACKEND_URL +
        process.env.REACT_APP_SCHOOL_PRODUCTS +
        '/' +
        idCourse,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Token token=' + process.env.REACT_APP_TOKEN_EAD,
        },
        method: 'GET',
      }
    )
      .then((res) => res.json())
      .then(function (res) {
        setLogo(res.logo && res.logo);
        setTitle(res.title && res.title);
        if (res.title) {
          localStorage.setItem('course_name', JSON.stringify(res.title));
        }
        if (res.payment_options && res.payment_options[0].price) {
          setPrice(res.payment_options[0].price);
          localStorage.setItem(
            'omd_course_price',
            JSON.stringify(res.payment_options[0].price)
          );
        }
      })
      .catch(function (res) {
        // console.log(res);
      });
  }, []);

  return (
    <div className="name-course-size">
      <div className="container-course card">
        <img src={logo} />
        <div>
          <div className="name-value">
            <text>{title && 'Infromações da compra'}</text>
            <h2>{title}</h2>
            <span id="price">{price && 'R$ ' + formatCoin(price)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const WhoPay = ({ onChange }) => {
  return (
    <div>
      <div className="who-pay">
        <span className="who-pay-title">Quem irá pagar pelo curso?</span>
      </div>
      <div>
        <input
          type="radio"
          value="0"
          name="gender"
          onChange={(event) => onChange(event.target.value)}
        />
        <span className="input-who-pay text-color-grey">Eu mesmo</span>
        <input
          type="radio"
          value="1"
          name="gender"
          onChange={(event) => onChange(event.target.value)}
        />{' '}
        <span className="text-color-grey">Minha empresa</span>
      </div>
    </div>
  );
};

export const StepperStudant = ({ onChange }) => {
  return (
    <div className="stepper-conteiner">
      <div className="stepper-data">
        <button class="active">1. Seus Dados</button>
        <div className="icon-arrow">
          <BsArrowRight />
        </div>
        <button>2. Seu Endereço</button>
        <div className="icon-arrow">
          <BsArrowRight />
        </div>
        <button>3. Pagamento</button>
      </div>
    </div>
  );
};

export const StepperCompany = ({ step, pay }) => {
  return (
    <div className="stepper-conteiner">
      <div className="stepper-data">
        <button className={step === 0 && 'active'}>
          1. Dados {pay === '1' ? 'da Empresa' : 'do Usuário'}
        </button>
        <div className="icon-arrow">
          <BsArrowRight />
        </div>
        <button className={step === 1 && 'active'}>
          2. {pay === '1' ? 'Endereço da Empresa' : 'Seu Endereço'}
        </button>
        {pay === '1' && (
          <>
            <div className="icon-arrow">
              <BsArrowRight />
            </div>
            <button className={step === 2 && 'active'}>
              3. Dados do aluno
            </button>
          </>
        )}
        <div className="icon-arrow">
          <BsArrowRight />
        </div>
        <button className={[3, 4, 5].includes(step) && 'active'}>
          {pay === '1' ? '4.' : '3.'} Pagamento
        </button>
      </div>
    </div>
  );
};

export const Redirect = ({ setStep }) => {
  useEffect(() => {
    setStep(3);
  }, []);

  return <div />;
};

export const DataCard = ({
  pay,
  setPay,
  step,
  setStep,
  paymentType,
  setPaymentType,
}) => {
  const [boletoUrl, setBoletoUrl] = useState('');
  const [errorCredit, setErrorCredit] = useState('');
  const [creditCardState, setCreditCardState] = useState({
    number: '',
    expiry: '',
    cvc: '',
    name: '',
    focus: '',
  });
  const validate = () => {
    const userErrors = JSON.parse(localStorage.getItem('dataErros'));

    if ((step === 4 && pay === '0') || (step === 4 && pay === '1')) {
      setStep(step + 1);
      removeItems();
    } else {
      if (Object.keys(userErrors).length === 0) {
        setStep(step + 1);
      }
    }
  };

  return (
    <div className="card-container">
      <div className="card data-card">
        <div className="card-img-data">
          <img className="img-card" src="img/imagem-card-dados.jpeg"></img>
          <img className="img-card-smartphone" src="img/img-celular.png"></img>
        </div>
        <div className="field-data">
          <div className="field-data-container">
            {step === 0 && (
              <>
                <WhoPay value={pay} onChange={setPay} />
                {pay === '0' && <UserData />}
                {pay === '1' && <CompanyData />}
              </>
            )}
            {step === 1 && <Address pay={pay} />}
            {step === 2 ? (
              pay === '1' ? (
                <StudentData />
              ) : (
                <Redirect setStep={setStep} />
              )
            ) : (
              <></>
            )}
            {step === 3 && (
              <FormOfPayment
                setBoletoUrl={setBoletoUrl}
                setPaymentType={(type) => {
                  setPaymentType(type);
                  setStep(step + 1);
                }}
              />
            )}
            {step === 4 && (
              <>
                {paymentType === 1 && <DataForTransfer paymentType={1} />}
                {paymentType === 2 && <DataForTransfer paymentType={2} />}
                {paymentType === 3 && (
                  <DataForTransfer paymentType={3} boletoUrl={boletoUrl} />
                )}
                {paymentType === 4 && (
                  <>
                    <CreditCard
                      state={creditCardState}
                      setState={setCreditCardState}
                    />
                    {errorCredit && (
                      <p className="error-feedback">{errorCredit}</p>
                    )}
                  </>
                )}
              </>
            )}
            {step === 5 && <Success />}
          </div>
          {pay && (
            <FooterCard
              paymentType={paymentType}
              setErrorCredit={setErrorCredit}
              setStep={setStep}
              creditCardState={creditCardState}
              step={step}
              onBack={() =>
                setStep(pay === '0' && step === 3 ? step - 2 : step - 1)
              }
              onNext={validate}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const FooterCard = ({
  setErrorCredit,
  step,
  onBack,
  creditCardState,
  onNext,
  setStep,
  paymentType,
}) => {
  const [loading, setLoading] = useState(false);
  const submitCredit = async (type) => {
    setLoading(true);
    const whoPay = JSON.parse(localStorage.getItem('who_pay'));
    const userData = JSON.parse(localStorage.getItem('user_data'));
    const addressData = JSON.parse(localStorage.getItem('address_data'));
    const companyInfo = JSON.parse(localStorage.getItem('company_data'));
    const studentInfo = JSON.parse(localStorage.getItem('student_data'));
    const courseId = JSON.parse(localStorage.getItem('omd_course_id'));
    const price = JSON.parse(localStorage.getItem('omd_course_price'));
    const coupon =
      localStorage?.getItem('omd_coupon') !== 'undefined'
        ? JSON.parse(localStorage?.getItem('omd_coupon'))
        : '';
    const coursePrice = formatPriceToDecimel(price);
    const customerInfo = getCustomerInfo(whoPay, {
      userData,
      addressData,
      companyInfo,
      studentInfo,
    });
    console.log(creditCardState.installments);
    const responseBody = {
      ...(coupon ? { coupon_id: coupon } : {}),
      course_id: courseId,
      payment_type:
        type === 'BOLETO'
          ? 'BOLETO'
          : type === 'CREDIT_CARD'
          ? 'CREDIT_CARD'
          : '',
      customer_info: customerInfo,
      ...(type === 'BOLETO'
        ? {
            boleto_info: {
              billing_type: 'BOLETO',
              description: 'payment with boleto',
              value: coursePrice,
            },
          }
        : {}),
      ...(type === 'CREDIT_CARD'
        ? {
            credit_info: {
              billing_type: 'CREDIT_CARD',
              description: 'payment with credit',
              value: coursePrice,
              installment_count: creditCardState.installments ?? 1,
              credit_card: {
                holder_name: creditCardState.name,
                number: creditCardState.number,
                expiry_month: creditCardState.expiry?.split('/')[0],
                expiry_year: `20${creditCardState.expiry?.split('/')[1]}`,
                ccv: creditCardState.cvc,
              },
              credit_card_holder_info: customerInfo,
            },
          }
        : {}),
    };
    try {
      console.log(JSON.stringify(responseBody));
      const response = await customPost({
        url: `/checkout/asaas`,
        body: responseBody,
      });
      window.open(response.transaction_receipt_url, '_blank');
      setStep(5);
      setErrorCredit('');
    } catch (error) {
      const errorMessage = error?.description
        ? error?.description
        : 'erro em realizar o pagamento, tente novamente mais tarde';
      setErrorCredit(errorMessage);
      setLoading(false);
    }
    setLoading(false);
  };
  return (
    <div>
      {step === 3 ? <PagamentoSeguroLogo /> : <hr />}
      <div className="footer-card">
        {![4, 5].includes(step) && (
          <>
            {step !== 0 && (
              <button type="button" className="button-return" onClick={onBack}>
                VOLTAR
              </button>
            )}
            {step !== 3 && (
              <button type="button" className="button-next" onClick={onNext}>
                PRÓXIMO
              </button>
            )}
          </>
        )}
        {step === 4 && (
          <>
            <PagamentoSeguroLogo />
            <ButtonFinish
              loading={loading}
              disabled={loading}
              onBack={onBack}
              onClick={() => {
                paymentType === 4 ? submitCredit('CREDIT_CARD') : setStep(5);
              }}
            />
          </>
        )}
        {step === 5 && (
          <button
            type="button"
            onClick={() => (window.location.href = 'https://ead.omd.com.br/')}
            className="back-my-courses"
          >
            Ir para o início do site
          </button>
        )}
      </div>
    </div>
  );
};

const PagamentoSeguroLogo = () => {
  return (
    <div className="safe-payment">
      <div className="protected">
        <ProtectedSvg />
        <span>Pagamento seguro com a Asaas</span>
      </div>
      <AsaasSvg className="asaas-log" />
    </div>
  );
};
const OmdFooter = ({}) => {
  return (
    <div className="omd-footer">
      <span className="omd-title-footer">OMD Soluções</span>
      <span>(11) 3280-9820 | (48) 4009-3800 | omd@omd.com.br</span>
      <span className="link-diferent">
        <a href="https://omd.com.br/wp-content/uploads/2021/04/Pol%C3%ADtica-de-Privacidade-V.2-OMD.pdf">
          Política de Privacidade
        </a>
      </span>
      <span className="link-diferent">
        <a href="https://omd.com.br/wp-content/uploads/2022/01/C%C3%93DIGO-DE-CONDUTA-%C3%89TICA-OMD-v2.0.pdf">
          Código de Ética
        </a>
      </span>
      <span>Atendemos em todo Brasil</span>
      <span>Por OMD Soluções</span>
    </div>
  );
};
