import React, { useState } from 'react';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';

export default function CreditCard({ setState, state }) {
  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    if (name === 'expiry') {
      const formattedValue = value
        .replace(/\D/g, '') // Remove non-digit characters (allow only numbers)
        .replace(/(\d{2})(\d)/, '$1/$2') // Add a slash between the first two and last two digits
        .substr(0, 5);
      setState((prev) => ({ ...prev, [name]: formattedValue }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleInputFocus = (evt) => {
    setState((prev) => ({ ...prev, focus: evt.target.name }));
  };

  return (
    <div>
      <Cards
        locale={{
          valid: 'Validade',
        }}
        placeholders={{
          name: 'NOME NO CARTÃO',
        }}
        number={state.number}
        expiry={state.expiry}
        cvc={state.cvc}
        name={state.name}
        focused={state.focus}
      />
      <form className="card-forum-container">
        <input
          name="number"
          required
          placeholder="Número do cartão"
          value={state.number}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          className="card-input"
        />
        <input
          name="name"
          placeholder="Nome do cartão"
          value={state.name}
          required
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          className="card-input"
        />
        <input
          name="expiry"
          required
          pattern="\d{2}/\d{2}"
          placeholder="Validade do cartão"
          value={state.expiry}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          className="card-input"
        />
        <input
          name="cvc"
          required
          placeholder="CVC"
          pattern="\d{3,4}"
          value={state.cvc}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          className="card-input"
        />
        <select
          name="installments"
          required
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          className="card-input"
        >
          {Array.from({ length: 6 }, (_, index) => index + 1).map((item) => {
            return (
              <option value={item}>
                {item} {item === 1 ? 'parcela' : 'parcelas'}
              </option>
            );
          })}
        </select>
      </form>
    </div>
  );
}
