import React from 'react';

export default function AsaasSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="92"
      height="36"
      viewBox="0 0 92 36"
      fill="none"
    >
      <path
        d="M85.2018 24.5079C84.6861 26.8302 83.1075 27.8141 80.9649 28.0913C78.3889 28.4259 75.9122 28.1037 73.5517 26.9376C72.9343 26.6329 72.6777 26.3158 73.0795 25.5991C73.433 24.9699 73.7042 24.6452 74.4668 25.0748C76.4448 26.191 78.5899 26.4007 80.7954 26.0711C81.1901 26.0112 81.5944 25.9113 81.9503 25.7315C82.6427 25.3819 83.1051 24.84 83.0567 23.9685C83.0083 23.087 82.4708 22.6201 81.7372 22.3829C81.0545 22.1631 80.3451 22.0333 79.6454 21.8635C79.0232 21.5164 78.3744 21.6862 77.7304 21.7611C75.8468 21.6662 73.9995 21.5039 73.1982 19.2989C73.2732 18.6422 73.2829 17.988 73.1933 17.3337C73.8664 15.3485 75.52 14.9315 77.251 14.6418C78.1323 14.7392 79.0135 14.7617 79.8924 14.6318C80.4371 14.9215 81.5048 14.3746 81.4394 15.6307C81.3741 16.8792 81.0932 17.0116 79.6527 16.5546C78.9288 16.3823 78.2073 16.3723 77.4859 16.5596C77.0307 16.7094 76.5683 16.8393 76.1228 17.0091C75.4982 17.2488 75.1132 17.7208 75.1641 18.42C75.2173 19.1416 75.7258 19.4263 76.3625 19.6061C77.5294 19.9357 78.7618 19.6036 79.9118 20.0181C80.2798 20.3502 80.6962 20.1704 81.1005 20.138C83.1318 20.4626 84.7079 21.3566 85.2042 23.6089C85.1993 23.9086 85.2018 24.2082 85.2018 24.5079Z"
        fill="white"
      />
      <path
        d="M33.4686 5.60474C33.7955 4.67831 34.1538 4.33121 34.9043 5.33006C36.5482 7.51503 38.778 8.97833 41.0078 10.4391C41.86 10.9985 42.78 11.463 43.5087 12.2196C45.3754 14.1523 45.8354 16.6195 44.7072 19.0717C44.2084 20.1554 43.6516 21.2142 43.0584 22.248C42.7364 22.8098 42.7752 23.0171 43.4385 23.107C45.2374 23.3517 47.0241 23.3767 48.927 22.9597C48.3315 21.8285 47.7843 20.7772 47.225 19.7309C45.5158 16.5371 46.2663 13.2584 49.2563 11.3006C52.0429 9.47526 54.8732 7.73727 57.0061 5.04039C57.621 4.26379 58.0036 4.98296 58.1295 5.5523C58.5193 7.31276 58.0689 8.92839 57.1054 10.3917C56.8463 10.7837 56.6187 11.1233 56.8294 11.6003C56.7446 12.0972 56.7059 12.5917 56.8536 13.0836C56.6308 14.5793 55.9626 15.8129 54.8175 16.7618C54.4253 17.0865 54.2195 17.461 54.1614 18.0129C53.9846 19.7234 53.2147 21.0943 51.687 21.8859C50.9414 22.2729 50.9801 22.7 51.365 23.3342C52.0381 24.4504 52.653 25.6041 53.2946 26.7403C53.5343 27.1648 54.1541 27.5169 53.7425 28.0912C53.4375 28.5182 51.9461 28.1586 51.612 27.6143C51.181 26.9151 50.7574 26.2084 50.3918 25.4717C50.1473 24.9798 49.8834 24.8849 49.3507 24.9923C46.995 25.4618 44.6176 25.4593 42.2692 24.9648C41.6687 24.84 41.4629 25.0897 41.2329 25.4942C40.848 26.1734 40.4776 26.8626 40.0854 27.5369C39.9643 27.7441 39.8312 28.0238 39.6423 28.0937C39.0685 28.3085 38.3374 28.4283 37.9016 28.0812C37.4174 27.6967 38.0614 27.2072 38.2647 26.8177C38.9112 25.5766 39.6133 24.3655 40.3323 23.1694C40.6277 22.675 40.7584 22.3778 40.1217 22.0207C38.4996 21.1093 37.5021 19.7259 37.4513 17.7482C37.4392 17.2637 37.0978 17.0265 36.8 16.7568C35.2408 15.346 34.437 13.6305 34.8268 11.458C34.9019 11.0434 34.7857 10.7438 34.5533 10.4317C33.977 9.66254 33.6817 8.76358 33.4807 7.82467C33.5703 7.08802 33.5969 6.34638 33.4686 5.60474Z"
        fill="white"
      />
      <path
        d="M29.3118 21.7661C28.5007 21.8235 27.709 21.6662 26.9416 21.4365C25.5494 21.0219 24.6367 19.8808 24.5229 18.5173C24.4164 17.2488 25.2274 15.9253 26.5251 15.2711C27.2514 14.904 28.0359 14.7442 28.83 14.6318C29.7088 14.7517 30.5901 14.7467 31.4713 14.6343C32.0016 14.8616 32.8465 14.5095 33.0111 15.3985C33.2097 16.4722 32.5632 16.9442 31.481 16.5871C30.5925 16.3573 29.7064 16.3623 28.8179 16.5846C28.4789 16.7019 28.1351 16.8068 27.801 16.9392C27.157 17.1939 26.6389 17.6059 26.6777 18.38C26.7188 19.1691 27.2781 19.5237 27.9753 19.6061C29.3602 19.7709 30.7523 19.8708 32.1323 20.0531C33.5002 20.2329 34.8342 20.5575 35.8244 21.6662C36.236 22.1257 36.5967 22.6276 36.5531 23.3118C36.374 23.8087 36.3764 24.3081 36.5531 24.8051C36.3812 26.5056 35.2216 27.3596 33.8367 27.7691C30.8177 28.6631 27.8664 28.286 25.0241 26.9101C24.3341 26.5755 24.3147 26.1585 24.6416 25.5192C24.9636 24.89 25.2783 24.6702 25.9562 25.0548C27.9753 26.2009 30.1543 26.3907 32.401 26.0711C32.8852 26.0012 33.3452 25.8688 33.752 25.5742C34.3137 25.1671 34.6792 24.6527 34.5993 23.9036C34.5219 23.1595 34.0837 22.6725 33.4348 22.4578C32.6383 22.1956 31.8103 22.0358 30.9944 21.8335C30.4424 21.5488 29.8759 21.6812 29.3118 21.7661Z"
        fill="white"
      />
      <path
        d="M28.8154 16.5845C29.704 16.1625 30.5925 16.155 31.4786 16.587C30.5901 16.587 29.704 16.587 28.8154 16.5845Z"
        fill="white"
      />
      <path
        d="M31.4713 14.6368C30.5901 14.939 29.7112 14.944 28.83 14.6343C29.7112 14.5045 30.5901 14.5045 31.4713 14.6368Z"
        fill="white"
      />
      <path
        d="M79.89 14.6318C79.0112 14.9489 78.1299 14.9339 77.2487 14.6418C78.1275 14.4994 79.0088 14.5119 79.89 14.6318Z"
        fill="white"
      />
      <path
        d="M33.4687 5.60474C33.7713 6.34388 33.7568 7.08552 33.4808 7.82966C33.3355 7.08802 33.3113 6.34888 33.4687 5.60474Z"
        fill="white"
      />
      <path
        d="M77.481 16.5595C78.2024 16.1824 78.9263 16.1874 79.6478 16.5545C78.9263 16.557 78.2049 16.5595 77.481 16.5595Z"
        fill="white"
      />
      <path
        d="M73.1909 17.3336C73.4621 17.9879 73.4572 18.6446 73.1957 19.2988C73.0069 18.6446 72.9924 17.9904 73.1909 17.3336Z"
        fill="white"
      />
      <path
        d="M77.7279 21.7635C78.3816 21.4988 79.028 21.364 79.643 21.8659C79.0062 21.8309 78.3671 21.796 77.7279 21.7635Z"
        fill="white"
      />
      <path
        d="M29.3118 21.766C29.8856 21.4688 30.4497 21.3864 30.9944 21.8334C30.4327 21.8109 29.8735 21.7885 29.3118 21.766Z"
        fill="white"
      />
      <path
        d="M56.8609 13.0811C56.5462 12.5916 56.568 12.0972 56.8367 11.5978C56.9578 12.0897 56.9335 12.5866 56.8609 13.0811Z"
        fill="white"
      />
      <path
        d="M14.3013 25.2744C14.7806 24.9348 15.26 24.9323 15.7394 25.2744C15.26 25.2744 14.7806 25.2744 14.3013 25.2744Z"
        fill="white"
      />
      <path
        d="M62.9596 25.2744C63.439 24.9373 63.9183 24.9298 64.3977 25.2794C63.9183 25.2769 63.439 25.2744 62.9596 25.2744Z"
        fill="white"
      />
      <path
        d="M36.5531 24.805C36.2045 24.3081 36.2045 23.8087 36.5531 23.3118C36.708 23.8087 36.7056 24.3056 36.5531 24.805Z"
        fill="white"
      />
      <path
        d="M81.0956 20.1379C80.6719 20.3776 80.2628 20.485 79.9069 20.0181C80.3039 20.058 80.6985 20.098 81.0956 20.1379Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5461 24.9923C12.4443 25.1921 13.3643 25.3694 14.3012 25.2745C14.7806 25.1197 15.26 25.1197 15.7417 25.2745C16.594 25.3644 17.4341 25.2121 18.2572 25.0373C18.9327 24.8924 19.3685 24.9823 19.6832 25.7015C19.9154 26.2305 20.2265 26.7237 20.537 27.2159C20.6442 27.3858 20.7513 27.5556 20.855 27.7266C21.3586 28.5582 22.2495 28.4034 22.7991 28.1287C23.3502 27.8531 23.0292 27.4467 22.7571 27.1023C22.6765 27.0003 22.6003 26.9038 22.5522 26.8177C20.625 23.3792 18.6712 19.9581 16.6787 16.5596C16.5809 16.3929 16.4959 16.2056 16.4098 16.016C16.1168 15.3703 15.8111 14.6968 14.9428 14.7142C14.1335 14.7296 13.8436 15.3701 13.5642 15.9877C13.4816 16.17 13.4 16.3504 13.3062 16.5121C11.7059 19.2733 10.1265 22.0466 8.54695 24.8201C7.97091 25.8316 7.39485 26.843 6.81775 27.854C6.85406 27.8989 6.85406 27.9414 6.82259 27.9863C8.33817 28.5582 8.7788 28.4059 9.56564 27.0374C9.65092 26.8884 9.739 26.7406 9.82711 26.5928C10.0342 26.2455 10.2415 25.8978 10.413 25.5317C10.6624 24.9998 10.9505 24.86 11.5461 24.9923ZM14.4992 18.3094C13.5896 19.9195 12.7519 21.4024 11.8608 22.9771C14.0276 23.3941 16.0105 23.4091 18.1386 22.9871C17.5896 22.0389 17.0705 21.1438 16.557 20.2585C16.0266 19.344 15.5023 18.4399 14.9573 17.4985C14.8023 17.7729 14.6497 18.0429 14.4992 18.3094Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M62.539 25.2818C62.6806 25.2758 62.822 25.2698 62.9595 25.2744C63.4389 25.1221 63.9182 25.1171 64.3976 25.2744C65.3345 25.3718 66.2545 25.187 67.1552 24.9998C67.7604 24.8749 68.0364 25.0722 68.2931 25.5741C68.649 26.2708 69.0412 26.95 69.4431 27.6192C69.7651 28.1536 71.2177 28.5432 71.5784 28.1411C71.9648 27.7094 71.6851 27.395 71.4229 27.1C71.3301 26.9957 71.2396 26.8939 71.1814 26.7902C69.539 23.8621 67.8727 20.948 66.2059 18.0331C65.7547 17.2441 65.3035 16.455 64.8527 15.6656C64.1046 14.3546 63.1798 14.3571 62.4317 15.6731C62.15 16.1682 61.8683 16.6632 61.5865 17.1583C59.7496 20.386 57.913 23.613 56.091 26.8476C56.0385 26.9407 55.9607 27.0426 55.8803 27.1481C55.6305 27.4756 55.3548 27.837 55.7302 28.0712C56.2895 28.4233 57.1344 28.5032 57.73 27.8764C57.9479 27.6467 58.1077 27.3271 58.2142 27.0199C58.8098 25.3019 59.8412 24.5428 61.6424 25.207C61.914 25.3084 62.2268 25.2951 62.539 25.2818ZM63.618 17.496C62.5479 19.3913 61.5819 21.1018 60.5312 22.9596C62.4462 23.3717 64.2039 23.3941 65.9664 23.1319C66.0088 23.1256 66.0555 23.1208 66.104 23.1159C66.4183 23.0838 66.8034 23.0444 66.516 22.5251C65.8608 21.3411 65.1784 20.1724 64.4742 18.9662C64.1922 18.4833 63.9067 17.9943 63.618 17.496Z"
        fill="white"
      />
    </svg>
  );
}
