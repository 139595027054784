export const formatCoin = (value) => {
  value = value + '';
  value = parseInt(value.replace(/[\D]+/g, ''));
  value = value + '';
  value = value.replace(/([0-9]{2})$/g, ',$1');

  if (value.length > 6) {
    value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
  }

  return value;
};
export function formatPrice(number) {
  const numString = number?.toString();
  const decimal = numString?.slice(-2);
  const integerPart = numString?.slice(0, -2);
  return `${integerPart},${decimal}`;
}
export function formatPriceToDecimel(number) {
  const numString = number?.toString();
  const decimal = numString?.slice(-2);
  const integerPart = numString?.slice(0, -2);
  return parseFloat(`${integerPart}.${decimal}`);
}
export const cpfCheck = (value) => {
  const fieldValue = value;
  if (fieldValue.length < 11 && fieldValue.length > 0) {
    return 'CPF incompleto';
  } else if (fieldValue.length === 11) {
    if (!cpfValidation(fieldValue)) {
      return 'CPF inválido';
    }
  }
};

const cpfValidation = (cpf) => {
  var sum;
  var rest;
  sum = 0;
  if (cpf === '00000000000') return false;

  for (var i = 1; i <= 9; i++)
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpf.substring(9, 10))) return false;

  sum = 0;
  for (var t = 1; t <= 10; t++)
    sum = sum + parseInt(cpf.substring(t - 1, t)) * (12 - t);
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpf.substring(10, 11))) return false;
  return true;
};

export const rejectInicialSpace = (value) => {
  return value.trim() !== '' ? value : '';
};

export const rejectNumbers = (value) => {
  return /^[a-záàâãéèêíïóôõöúçñ ]+$/i.test(value)
    ? value
    : value.replace(value[value.length - 1], '');
};

export const justNumbers = (value) => {
  return value
    .replace(/[A-Za-z]/g, '')
    .replace(/\W|_/g, '')
    .trim();
};

export const dddMask = (value) => {
  let v = value.replace(/^(\d{2})(\d)/g, '($1) $2');
  v = v.replace(/(\d)(\d{4})$/, '$1-$2');
  return v;
};

export const validateCNPJ = (val) => {
  if (val.length == 14) {
    var cpf = val.trim();

    cpf = cpf.replace(/\./g, '');
    cpf = cpf.replace('-', '');
    cpf = cpf.split('');

    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] != cpf[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
      v1 += cpf[i] * p;
    }

    v1 = (v1 * 10) % 11;

    if (v1 == 10) {
      v1 = 0;
    }

    if (v1 != cpf[9]) {
      return false;
    }

    for (var i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
      v2 += cpf[i] * p;
    }

    v2 = (v2 * 10) % 11;

    if (v2 == 10) {
      v2 = 0;
    }

    if (v2 != cpf[10]) {
      return false;
    } else {
      return true;
    }
  } else if (val.length == 18) {
    var cnpj = val.trim();

    cnpj = cnpj.replace(/\./g, '');
    cnpj = cnpj.replace('-', '');
    cnpj = cnpj.replace('/', '');
    cnpj = cnpj.split('');

    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cnpj.length > i; i++) {
      if (cnpj[i - 1] != cnpj[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v1 += cnpj[i] * p1;
      } else {
        v1 += cnpj[i] * p2;
      }
    }

    v1 = v1 % 11;

    if (v1 < 2) {
      v1 = 0;
    } else {
      v1 = 11 - v1;
    }

    if (v1 != cnpj[12]) {
      return false;
    }

    for (var i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v2 += cnpj[i] * p1;
      } else {
        v2 += cnpj[i] * p2;
      }
    }

    v2 = v2 % 11;

    if (v2 < 2) {
      v2 = 0;
    } else {
      v2 = 11 - v2;
    }

    if (v2 != cnpj[13]) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const removeItems = () => {
  localStorage.removeItem('user_data');
  localStorage.removeItem('company_data');
  localStorage.removeItem('student_data');
  localStorage.removeItem('omd_course_id');
  localStorage.removeItem('dataErros');
  localStorage.removeItem('course_name');
  localStorage.removeItem('omd_coupon');
  localStorage.removeItem('omd_course_price');
  localStorage.removeItem('omd_course_price_with_coupon');
  localStorage.removeItem('address_data');
  localStorage.removeItem('checkout_id');
  localStorage.removeItem('who_pay');
  localStorage.removeItem('who_pay');
};
