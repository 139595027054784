import { api, defaultParams } from './axios';

export default async (params) => {
  // console.log(JSON.stringify(params.body));
  const response = await (
    await api.post(params.url, params.body, {
      ...defaultParams(),
    })
  ).data;
  return response;
};
