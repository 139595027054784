export const getCustomerInfo = (customerType, customerInfo) => {
  if (customerType === 'me') {
    return {
      name: customerInfo?.userData?.name,
      email: customerInfo?.userData?.email,
      phone: customerInfo?.userData?.phone,
      mobile_phone: customerInfo?.userData?.phone2,
      cpf_cnpj: customerInfo?.userData?.cpf,
      postal_code: customerInfo?.addressData?.cep,
      address: customerInfo?.addressData?.address,
      address_number: customerInfo?.addressData?.number,
      complement: customerInfo?.addressData?.complement,
      province: customerInfo?.addressData?.uf,
      notification_disabled: false,
    };
  } else if (customerType === 'company') {
    const obs = {
      name: customerInfo?.studentInfo?.name,
      cpfCnpj: customerInfo?.studentInfo?.cpf,
      email: customerInfo?.studentInfo?.email,
    };
    const stringObs = JSON.stringify(obs);
    return {
      name: customerInfo?.companyInfo?.corporate_name,
      email: customerInfo?.companyInfo?.responsible_payment_email,
      phone: customerInfo?.companyInfo?.phone,
      company: customerInfo?.companyInfo?.corporate_name,
      cpf_cnpj: customerInfo?.companyInfo?.cnpj,
      postal_code: customerInfo?.addressData?.cep,
      address: customerInfo?.addressData?.address,
      address_number: customerInfo?.addressData?.number,
      complement: customerInfo?.addressData?.complement,
      province: customerInfo?.addressData?.uf,
      notification_disabled: false,
      observations: stringObs,
      additional_emails: customerInfo?.studentInfo?.email,
    };
  }
};
