import React from 'react';

const Success = () => {
  return <div>
      <SuccessImageAndText/>
  </div>;
}

export default Success;

const SuccessImageAndText = () => {
    return <div className='success-content'>
        <img className='img-success' src="img/success.png"></img>
        <span className='title-trasnfer-card'>Obrigado! Sua compra está sendo processada.</span>
        <span className='subtitle-trasnfer-card'>Em breve enviaremos as instruções de acesso à plataforma para o seu e-mail.</span>
    </div>;
  }