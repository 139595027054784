import React from 'react';

export default function ProtectedSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_509_1284)">
        <path
          d="M11.298 2.195C11.6926 2.04735 12.1238 2.0281 12.53 2.14L12.702 2.195L19.702 4.82C20.0569 4.95309 20.3667 5.1843 20.5953 5.4867C20.8239 5.7891 20.9618 6.15022 20.993 6.528L21 6.693V12.056C21 13.6764 20.5624 15.2668 19.7336 16.6592C18.9048 18.0516 17.7154 19.1944 16.291 19.967L16.025 20.106L12.671 21.783C12.4863 21.8752 12.2846 21.9283 12.0785 21.939C11.8723 21.9496 11.6662 21.9176 11.473 21.845L11.329 21.783L7.975 20.106C6.52561 19.3813 5.29878 18.2787 4.424 16.9146C3.54923 15.5505 3.05898 13.9756 3.005 12.356L3 12.056V6.693C3.00001 6.31413 3.10763 5.94305 3.31033 5.62297C3.51304 5.30288 3.8025 5.04696 4.145 4.885L4.298 4.82L11.298 2.195ZM12 4.068L5 6.693V12.056C5.00003 13.311 5.33745 14.5429 5.97696 15.6228C6.61646 16.7026 7.53451 17.5907 8.635 18.194L8.87 18.317L12 19.882L15.13 18.317C16.2527 17.7557 17.2039 16.9029 17.8839 15.8479C18.5638 14.7928 18.9476 13.5743 18.995 12.32L19 12.056V6.693L12 4.068ZM15.433 8.629C15.613 8.44965 15.8544 8.34552 16.1084 8.33777C16.3623 8.33001 16.6097 8.41921 16.8003 8.58724C16.9908 8.75528 17.1103 8.98955 17.1344 9.24247C17.1585 9.49539 17.0854 9.748 16.93 9.949L16.847 10.043L11.613 15.278C11.4223 15.4686 11.1683 15.5826 10.8992 15.5983C10.63 15.6139 10.3645 15.5302 10.153 15.363L10.057 15.278L7.653 12.874C7.47175 12.6945 7.36597 12.4524 7.35732 12.1974C7.34867 11.9425 7.4378 11.6938 7.60647 11.5024C7.77513 11.311 8.01058 11.1913 8.26462 11.1678C8.51866 11.1443 8.77208 11.2188 8.973 11.376L9.067 11.459L10.835 13.227L15.433 8.629Z"
          fill="#FEFEFE"
        />
      </g>
      <defs>
        <clipPath id="clip0_509_1284">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
